import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Servicios/main'
import Adminitration from '../components/Servicios/administration'

const IndexPage = () => (
  <Layout>
    <Seo title="Servicios" />
      <Main />
      <Adminitration />
  </Layout>
)

export default IndexPage
